import { withZod } from '@remix-validated-form/with-zod'
import { ZodRawShape, z } from 'zod'
import { zfd } from 'zod-form-data'
import { NumberMaskType } from '~/components'
import { ModelCompany } from './company'
import { CoordinateLocation, Property, PropertySchema } from './listing'
import { ModelOrganization } from './organization'
import { ModelUser } from './user'

export type DataLayer = {
  _id: string
  tid: string
  created: string
  modified: string
  organization: ModelOrganization
  user: ModelUser
  company: ModelCompany
  slug: string
  name: string
  description?: string
  // labels, colors TODO
}

export type DataLayerEntry = {
  _id: string
  tid: string
  created: string
  modified: string
  organization: ModelOrganization
  user: ModelUser
  slug: string
  dataLayer: Pick<DataLayer, '_id' | 'tid' | 'slug' | 'name'>
  name: string
  description?: string
  property: Property
  location: CoordinateLocation
  meta: { [key: string]: string | number }
}

export const DataLayerSchema = z.object({
  _id: z.string(),
  tid: z.string(),
  slug: z.string(),
  created: z.string(),
  modified: z.string(),
  organization: z.custom<ModelOrganization>(),
  user: z.custom<ModelUser>(),
  company: z.custom<ModelCompany>(),
  name: zfd.text(z.string().min(1)),
  description: zfd.text(z.string().optional()),
}) satisfies z.ZodType<DataLayer>

export const dataLayerValidator = withZod(DataLayerSchema)

export const DataLayerEntrySchema = z.object({
  _id: z.string(),
  tid: z.string(),
  slug: z.string(),
  created: z.string(),
  modified: z.string(),
  organization: z.custom<ModelOrganization>(),
  user: z.custom<ModelUser>(),
  dataLayer: z.object({
    _id: z.string(),
    tid: z.string(),
    slug: z.string(),
    name: z.string(),
  }),
  name: zfd.text(z.string().min(1)),
  description: zfd.text(z.string().optional()),
  property: PropertySchema,
  location: z.object({
    type: z.string(),
    coordinates: z.array(zfd.numeric(z.number())),
  }),
  meta: z.object({}),
}) satisfies z.ZodType<DataLayerEntry>

export type DataLayerEntryField = {
  name: string
  label: string
  type: 'string' | 'number' | 'date'
  maskType?: keyof typeof NumberMaskType
  suffix?: string
  prefix?: string
}

export const DATA_LAYER_ENTRY_FIELDS: DataLayerEntryField[] = [
  {
    name: 'saleDate',
    label: 'Sale Date',
    type: 'date',
  },
  {
    name: 'buildingSf',
    label: 'Building SF',
    type: 'number',
  },
  {
    name: 'yearBuilt',
    label: 'Year Built',
    type: 'number',
    maskType: 'year',
  },
  {
    name: 'vacancy',
    label: 'Vacancy',
    type: 'number',
  },
  {
    name: 'salePrice',
    label: 'Sale Price',
    type: 'number',
    maskType: 'currency',
    prefix: '$',
  },
  {
    name: 'pricePerSf',
    label: 'Price Per SF',
    type: 'number',
    maskType: 'currency',
    prefix: '$',
    suffix: '/SF',
  },
  {
    name: 'actualCapRate',
    label: 'Actual Cap Rate',
    type: 'number',
  },
  {
    name: 'buyerCompany',
    label: 'Buyer Company',
    type: 'string',
  },
  {
    name: 'sellerCompany',
    label: 'Seller Company',
    type: 'string',
  },
  {
    name: 'anchorTenants',
    label: 'Anchor Tenants',
    type: 'string',
  },
]

export function getDataLayerEntryValidator(metaFields: DataLayerEntryField[]) {
  const metaFieldSchema: ZodRawShape = {}
  metaFields.forEach((field) => {
    if (field.type === 'number') {
      metaFieldSchema[field.name] = zfd.numeric(z.number().optional())
    } else {
      metaFieldSchema[field.name] = zfd.text(z.string().optional())
    }
  })
  const dataLayerEntryValidator = withZod(
    DataLayerEntrySchema.extend({
      meta: z.object(metaFieldSchema),
    })
  )
  return dataLayerEntryValidator
}
